import React, { memo, useState } from 'react'
import styles from './InMailCard.module.css'
import { Link, useNavigate } from 'react-router-dom'


const InMailCard = ({
    inMail
}) => {

    const navigate = useNavigate()

    const [reply_unread_count, setReplyUnreadCount] = useState(inMail?.reply_unread_count || 0)

    return (
        <Link
            to={`/in-mail/${inMail?.in_mail_id}`}
            state={{ in_mail: inMail }}
            className={styles.container}>
            <div className={styles.pictureContainer}>
                <img src={inMail?.sender?.profile?.prof_picture
                    ? inMail?.sender?.profile?.prof_picture
                    : require('assets/images/background_opinion.jpg')
                }
                    alt=''
                    className={styles.picture}
                />
            </div>

            <div className={styles.contentContainer}>
                <p className={styles.contentSenderName}>
                    {inMail?.sender?.user_name} {inMail?.sender?.user_surname}
                </p>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className={styles.contentTitle}>
                        {inMail?.in_mail_title || inMail?.forwarded_inmail?.in_mail_title}
                    </p>
                    {reply_unread_count > 0 &&
                        <span style={{
                            minWidth: '30px',
                            paddingTop: '2px',
                            paddingBottom: '2px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            backgroundColor: 'red',
                            borderRadius: '50px',
                            color: 'white',
                            fontWeight: '600',
                            textAlign: 'center'
                        }}>
                            {reply_unread_count}
                        </span>
                    }
                </div>

                <p className={styles.contentMessage}>
                    {
                        inMail?.last_reply_message ||
                        inMail?.in_mail_message ||
                        inMail?.forwarded_inmail?.in_mail_message
                    }
                </p>
            </div>
        </Link>
    )
}

export default memo(InMailCard)