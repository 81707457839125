import { api } from "api/api";
import { getPageNumberFromUrl } from "hooks/Utils/pageParams";


const fetchInMails = async (pageParam, accessToken) => {
    try {
        const request = await api(`api/in_mail?page=${pageParam}`, 'GET', {}, accessToken)
        const response = await request.json()
        console.log('fetch in_mail list response:', response?.data)

        const nextPage = getPageNumberFromUrl(response?.data?.next_page_url)
        const prevPage = getPageNumberFromUrl(response?.data?.prev_page_url)

        if (request?.status === 200) {
            if (response?.success) {
                return { data: response?.data?.data, nextPage, prevPage }
            } else {
                return { data: [], nextPage, prevPage }
            }
        } else {
            throw new Error('Something wrong happened')
        }
    } catch (error) {
        throw new Error(error);
    }
}

export { fetchInMails }