import React, { memo } from 'react'

const SondPicture = ({
  uri
}) => {
  return (
    <div>
      <img src={uri} alt='' />
    </div>
  )
}

export default memo(SondPicture)