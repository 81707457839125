import './translations/IMLocalize'
import AppStack from './routes/AppStack';
import AuthStack from './routes/AuthStack';
import { useAuthReducer } from './hooks/ReducerHooks/ReducerHooks'
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function App() {

  const { data: AuthData } = useAuthReducer()
  localStorage.setItem('language', AuthData.language)

  const theme = createTheme({
    palette: {
      primary: {
        main: '#F47233',
      },
      secondary: {
        main: '#4049354',
      },
    },
  });

  const LoadingAccount = () => {
    return (
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Spinner
            style={{
              marginTop: '45vh'
            }}
          />
        </div>
        <p style={{ textAlign: 'center', marginTop: 10 }}>
          {AuthData.language == 'en'
            ? 'Loading...'
            : 'Connexion...'
          }
        </p>
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      {AuthData?.isChangeAccount
        ? LoadingAccount()
        : !AuthData?.authorization
          ? <AuthStack />
          : <AppStack />
      }
    </ThemeProvider >
  )

}

export default App;
