import React, { useState } from 'react'
import styles from './UserCard.module.css'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { color } from 'hooks/Utils/color'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { api } from 'api/api'
import { Spinner } from 'react-bootstrap'


const UserCard = ({
    user_id,
    user,
    onRefresh = () => null
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authReducerState } = useAuthReducer()

    const [isLoading, setIsLoading] = useState(false)

    const [youFollow, setYouFollow] = useState(true)


    const handleUnMute = async () => {
        try {
            setIsLoading(true)
            const body = { user_id: user?.user_id }
            const request = await api(
                'api/profile/unmute',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            setIsLoading(false)
            if (request.ok && request.status === 200) {
                if (response?.success) {
                    onRefresh()
                }
            }
        } catch ({ message }) {
            setIsLoading(false)
            throw new Error(message)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.userContainer}>
                <ProfileCard
                    user={{
                        ...user,
                        profile: {
                            ...user?.profile,
                            prof_picture: user?.prof_picture
                        }
                    }}
                    pictureSize={60}
                />
            </div>
            <div className={styles.buttonContainer}>
                {isLoading ?
                    <div style={{ textAlign: 'center', padding: '15px' }}>
                        <Spinner />
                    </div>
                    :
                    <Button
                        onClick={handleUnMute}
                        variant={"outlined"}
                        color="warning"
                        style={{
                            backgroundColor: color.white
                        }}>
                        <span
                            style={{
                                color: color.primary,
                                fontSize: '12px',
                                fontWeight: '600'
                            }}>
                            {t('unMute')}
                        </span>
                    </Button>
                }
            </div>
        </div>
    )
}

export default UserCard