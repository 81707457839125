import React, { useEffect, useState } from 'react'
import styles from './InMaildetails.module.css'
import { useTranslation } from 'react-i18next'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header/Header'
import { api } from 'api/api'
import dayjs from 'dayjs'
import InMailMessageCard from './InMailMessageCard/InMailMessageCard'
import { BsReply } from "react-icons/bs";
import { color } from 'hooks/Utils/color'
import { TiArrowForwardOutline } from "react-icons/ti";
import { TfiMenu } from 'react-icons/tfi'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Alert } from '@mui/material'

const InMailDetails = () => {

    const {
        in_mail_id
    } = useParams();

    const location = useLocation();
    const { in_mail } = location.state

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const [message, setMessage] = useState(in_mail)
    const [error, setError] = useState(null)


    // MENU STATE
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

    const [openSnack, setOpenSnack] = useState(false);
    const handleClickSnack = () => { setOpenSnack(true) };
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };


    const goBack = () => navigate(-1)

    useEffect(() => {
        const confirmRead = async () => {
            try {
                if (!message?.in_mail_viewed) {
                    const request = await api(
                        `api/in_mail/${in_mail_id}/markAsRead`,
                        'GET',
                        null,
                        accessToken
                    )
                    const response = await request.json()
                    console.log('response confirm read:', response)
                }
            } catch (error) {
                console.error(error);
            }
        }
        confirmRead()
    }, [message?.receiver?.user_id, in_mail_id])



    useEffect(() => {
        const fetchInMailDetails = async () => {
            try {
                const request = await api(`api/in_mail/${in_mail_id}`, 'GET', {}, accessToken)
                const response = await request.json()
                console.log('fetch in_mail response:', response?.data)
                if (request?.status === 200) {
                    if (response?.success) {
                        setMessage(response?.data)
                    } else {
                        throw new Error('Something wrong happened')
                    }
                } else {
                    throw new Error('Something wrong happened')
                }
            } catch (error) {
                throw new Error(error);
            }
        }
        fetchInMailDetails()
    }, [in_mail_id])


    const handleDeleteInMail = async () => {
        try {
            if (in_mail_id) {
                setError(null)
                const request = await api(
                    `api/in_mail/${in_mail_id}`,
                    'DELETE',
                    null,
                    accessToken
                )
                const response = await request.json()
                console.log('Delete in mail response:', response)
                if (request.status === 200) {
                    if (response.success) {
                        goBack()
                    } else {
                        setError(i18n.language === 'en'
                            ? 'We encountered an error, please try again.'
                            : 'Nous avons rencontré une erreur, veuillez réessayer.'
                        )
                    }
                } else {
                    setError(i18n.language === 'en'
                        ? 'We encountered an error, please try again.'
                        : 'Nous avons rencontré une erreur, veuillez réessayer.'
                    )
                }
            }
        } catch (error) {
            console.error(error);
            setError(i18n.language === 'en'
                ? 'We encountered an error, please try again.'
                : 'Nous avons rencontré une erreur, veuillez réessayer.'
            )
        }
    }



    return (
        <div className={styles.page} >
            <Header title={'InMail'} goBack={goBack} />
   
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: -45,
            }}>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick} >
                    <TfiMenu size={28} color='#414141' />
                </Button>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <MenuItem onClick={handleDeleteInMail}>
                        {i18n.language === 'en'
                            ? 'Delete InMail'
                            : 'Supprimer InMail'
                        }
                    </MenuItem>
                </Menu>
            </div>

            <div className={styles.contentContainer}>
            {error &&
                    <Alert severity="error"
                        style={{
                            marginTop: '5px',
                            marginBottom: '5px'
                        }}>
                        {error}
                    </Alert>
                }
                <h3>
                    {in_mail?.in_mail_title}
                </h3>
                <p className={styles.senderText}>
                    {i18n.language === 'en'
                        ? 'Sent by'
                        : 'Envoyé par'
                    } {in_mail?.sender?.user_surname || ''} {in_mail?.sender?.user_name || ''} ({in_mail?.sender?.user_email})
                </p>
                <p className={styles.senderText}>
                    {
                        i18n.language === 'en'
                            ? 'on'
                            : 'le'
                    } {dayjs(message?.in_mail_sent_at).locale(i18n.language).format('DD MMM YYYY HH:mm')}
                </p>
                <p className={styles.receiverText}>
                    {i18n.language === 'en'
                        ? 'Sent to'
                        : 'Envoyé à'
                    } {message?.receiver?.user_surname || ''} {message?.receiver?.user_name || ''} ({message?.receiver?.user_email})
                </p>

                {(message?.cc && message?.cc?.length !== 0) &&
                    <div className={styles.ccContainer}>
                        <p className={styles.ccLabel}>
                            Cc:
                        </p>
                        <div className={styles.recipientCcContainer}>
                            {message?.cc?.map((item, index) => {
                                return (
                                    <p
                                        key={index?.toString()}
                                        className={styles.ccNameText}>
                                        {item?.user_surname || ''} {item?.user_name || ''} ({item?.user_email})
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                }


                <div>
                    {message?.replies?.map((item, index) => {
                        return (
                            <div key={index?.toString()}>
                                <InMailMessageCard
                                    message={item}
                                />
                            </div>
                        )
                    })}
                </div>

                <InMailMessageCard
                    message={message}
                />

                {message?.forwarded_inmail &&
                    <InMailMessageCard
                        message={message?.forwarded_inmail}
                    />
                }


                {/* RENDER REPLY AND FORWARD BUTTON */}
                <div className={styles.replyContainer}>

                    <Link
                        to={`/in-mail/new`}
                        state={{
                            in_mail: message,
                            type: 'reply'
                        }}
                        className={styles.replyButton}>
                        <BsReply
                            size={32}
                            style={{
                                color: color.primary,
                                marginRight: '5px',
                                marginTop: '-3px'
                            }}
                        />

                        {i18n.language === 'en'
                            ? 'Reply'
                            : 'Répondre'
                        }
                    </Link>

                    <Link
                        to={`/in-mail/new`}
                        state={{
                            in_mail: message,
                            type: 'forward'

                        }}
                        className={styles.forwardButton}>
                        {i18n.language === 'en'
                            ? 'Forward'
                            : 'Transférer'
                        }

                        <TiArrowForwardOutline
                            size={32}
                            style={{
                                color: color.primary,
                                marginLeft: '5px',
                                marginTop: '-3px'
                            }}
                        />
                    </Link>

                </div>

            </div>

        </div>
    )
}

export default InMailDetails