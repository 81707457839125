import React, { useEffect, useState } from 'react'
import styles from './EventList.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Header from 'components/Header/Header'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import Spinner from 'react-bootstrap/Spinner'
import { MdEvent } from 'react-icons/md';
import { useInfiniteQuery, useQuery } from 'react-query'
import InfiniteScroll from 'react-infinite-scroll-component'
import EventCard from './EventCard/EventCard'
import { eventKeys } from 'queries/QueryKeys'
import { fetchEvents } from 'queries/EventQueries'

const EventList = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()


    // REQUEST STATE
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)

    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)

    const {
        data,
        isFetching,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        eventKeys.events,
        ({ pageParam }) => fetchEvents(pageParam, accessToken), {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => lastPage.nextPage
    });
    // console.log(hasNextPage)
    const events = data?.pages[0]?.data?.data?.data || []


    // FETCHING DATA RENDER
    if (isFetching) {
        return (
            <div className={styles.container}>
                <Header title={t('myEvents')} goBack={goBack} />
                <div className={styles.loadingContent}>
                    <Spinner animation="border" variant="dark" />
                </div>
            </div>
        )
    }


    // FETCHING DATA RENDER
    if (events?.length === 0) {
        return (
            <div className={styles.container}>
                <Header title={t('myEvents')} goBack={goBack} />
                <div className={styles.emptyContainer}>
                    <div className={styles.emptyContent}>
                        <MdEvent className={styles.iconEmptyContent} />
                    </div>
                    <h5 className={styles.textEmptyContent}>
                        {t('noEventAvailable')}
                    </h5>
                </div>
            </div>
        )
    }


    const renderLoader = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '50px'
            }}>
                <Spinner animation="border" variant="dark" />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Header title={t('myEvents')} goBack={goBack} />
            <div id='content' className={styles.content}>
                <InfiniteScroll
                    dataLength={events.length}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    loader={renderLoader()}
                    scrollableTarget="content"
                    style={{
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}>
                    {/* RENDER ITEM */}
                    {events?.map((item, index) => {
                        return <EventCard key={index} event={item} />
                    })}
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default EventList