import React, { memo } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const LoadingPage = ({
    text = 'Loading...'
}) => {

    const { t } = useTranslation()


    return (
        <div style={{backgroundColor: 'white', width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{ textAlign: 'center' }}>
                <Spinner variant='secondary' />
                <h6 style={{color: '#808080', marginTop: '10px'}}>
                    {text}
                </h6>
            </div>

        </div>
    )
}

export default memo(LoadingPage)