import React, { Fragment, useEffect, useState } from 'react'
import './OneToOne.style.css'
import { useAccessToken, useAuthReducer, useOneToOneReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import TopBar from 'components/Topbar/Topbar';
import { TfiMenu } from 'react-icons/tfi'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import useReducerNotifAction from 'hooks/ReducerHooksAction/useReducerNotifAction';
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction';
import { api } from 'api/api';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { Alert, IconButton, Snackbar } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import useReducerOneToOneAction from 'hooks/ReducerHooksAction/useReducerOneToOneAction';
import { color } from 'hooks/Utils/color';
import ConversationCard from './ConversationCard/ConversationCard';
import { useNavigate } from 'react-router-dom';


const OneToOne = () => {

  const { t } = useTranslation()
  const accessToken = useAccessToken()
  const navigate = useNavigate()
  const { data: authReducerStore } = useAuthReducer()
  const { data: oneToOneData } = useOneToOneReducer()
  const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()
  const { REFRESH_MESSAGE_NUMBER, REFRESH_CONVERSATION, REFRESH_BUDDIES } = useReducerOneToOneAction()

  // DATA STATE
  const [conversations, setConversation] = useState([])
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false)


  const [search, setSearch] = useState('')

  const [showOnLine, setShowOnline] = useState(authReducerStore?.user?.user_show_last_seen)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };

  const [openSnack, setOpenSnack] = useState(false);
  const handleClickSnack = () => { setOpenSnack(true) };
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };



  const goToInMail = () => navigate('/in-mail')

  useEffect(() => {
    if (search) {
      const newData = oneToOneData.buddies.filter(
        user =>
          user?.user_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          user?.user_surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
          user?.user_username?.toLowerCase()?.includes(search?.toLowerCase()),
      )
      setConversation(newData)
    } else {
      setConversation(oneToOneData.conversations)
    }
  }, [search])


  useEffect(() => {
    fetchConversations()
  }, [oneToOneData?.message_number])

  const fetchConversations = async () => {
    try {
      setIsLoading(true)
      const request = await api(`api/my-messages?paginate&page=${1}`, 'GET', {}, accessToken)
      const response = await request.json()
      // console.log('Fetch conversations response:', response?.list?.data?.length)
      setIsLoading(false)
      if (request.ok && request.status === 200) {
        if (response.success) {
          if (response?.list?.next_page_url) {
            setHasMore(true)
          } else {
            setHasMore(true)
          }
          if (response?.list?.data?.length === 0) {
            setIsEmpty(true)
            return
          }
          setPage(2)
          setConversation(response?.list?.data)
          REFRESH_CONVERSATION(response?.list?.data)

          const numberOfMessage = response?.list?.data
            ?.map(message => message?.sent_messages?.length)
            ?.reduce(
              (previousValue, currentValue) => previousValue + currentValue, 0,
            )
          REFRESH_MESSAGE_NUMBER(numberOfMessage)
        }
      }
    } catch (error) {
      fetchConversations()
      console.error('Fetch conversations error:', error.message)
    }
  }


  const loadMoreConversations = async () => {
    try {
      if (hasMore && !isLoading) {
        setIsLoading(true)
        const request = await api(`api/my-messages?paginate&page=${page}`, 'GET', null, accessToken)
        const response = await request.json()
        // console.log('Load more conversations response:', response?.list?.data?.length)
        setIsLoading(false)
        if (request.ok && request.status === 200) {
          if (response.success) {

            if (response?.list?.next_page_url) {
              setHasMore(true)
            } else {
              setHasMore(false)
            }
            setConversation([...oneToOneData.conversations, ...response?.list?.data])
            REFRESH_CONVERSATION([...oneToOneData.conversations, ...response?.list?.data])

            const numberOfMessage = response?.list?.data
              ?.map(message => message?.sent_messages?.length)
              ?.reduce(
                (previousValue, currentValue) => previousValue + currentValue, 0,
              )
            REFRESH_MESSAGE_NUMBER(numberOfMessage)
          }
        }
      }
    } catch (error) {
      loadMoreConversations()
      console.log('Load more conversations error:', error.message)
    }
  }


  // HANDLE UPDATE LAST SEEN
  const handleChangeOnLineStatus = async () => {
    try {
      handleClose()
      const body = {
        user_show_last_seen: !showOnLine
      }
      const request = await api(`api/user/update-show-last-seen`, 'POST', body, accessToken)
      const response = await request.json()
      // console.log('Response updating last seen:', response)
      if (request.ok && request.status === 200) {
        if (response?.success) {
          setShowOnline(!showOnLine)
          handleClickSnack()
          updateUserData()
        }
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const updateUserData = async () => {
    try {
      const request = await api('api/get-user', 'GET', {}, accessToken)
      const response = await request.json()
      // console.log('Response fetch user:', response)
      if (request.ok && request.status === 200) {
        UPDATE_PROFILE(response?.user)
      }
    } catch (e) {
      console.warn('Error fetch user info:', e.message)
      throw new Error(e)
    }
  }


  const header = () => {
    if (isMobile) {
      return (
        <TopBar />
      )
    } else {
      return (
        <div className='header'>
          <p className='title'>{t('inbox')}</p>
        </div>
      )
    }
  }

  const renderLoader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
        <div style={{ textAlign: 'center' }}>
          <Spinner
            size="sm"
            role="status"
            aria-hidden="true"
            animation="border"
            variant='secondary'
            style={{
              width: '25px',
              height: '25px'
            }}
          />
          <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
            {t('loading')}
          </h6>
        </div>
      </div>
    )
  }


  const renderEmptyConversation = () => {
    if (isEmpty) {
      return (
        <div style={{ textAlign: 'center', marginTop: '15vh', paddingLeft: '10%', paddingRight: '10%' }}>
          <h5 style={{ color: color.dark }}>{t('no_conversations_available')}</h5>
          <h6 style={{ color: color.grayDark }}>{t('no_conversations_available_desc')}</h6>
        </div>
      )
    }
  }


  return (
    <div className='onetoone'>

      {/* TAB */}
      <div className='tab'>
        <p className='tablabel' style={{ color: color.primary }}>
          Inbox
        </p>
        <p className='tablabel' onClick={goToInMail} >
          InMail
        </p>
      </div>

      <div className='header'>
        <p className='title'>
          Inbox
        </p>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick} >
          <TfiMenu size={28} color='#414141' />
        </Button>
      </div>

      <input
        type='search'
        placeholder={`${t('search')}...`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className='search-input'
      />

      <div id="HomeScrollableDiv" style={{ overflowX: 'hidden', height: '80vh'}}>
        {renderEmptyConversation()}
        <InfiniteScroll
          dataLength={conversations.length}
          next={loadMoreConversations}
          hasMore={false}
          loader={renderLoader()}
          scrollableTarget="HomeScrollableDiv"
          scrollThreshold={0.9}
          style={{ overflowX: 'hidden', height: '87vh' }}>
          {conversations?.map((user, index) => {
            return (
              <ConversationCard
                key={index.toString()}
                user={user}
              />
            )
          })}
        </InfiniteScroll>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem onClick={handleChangeOnLineStatus}>
          {showOnLine ?
            <AiOutlineEye size={28} color='#414141' style={{ marginRight: '5px' }} />
            :
            <AiOutlineEyeInvisible size={28} color='#414141' style={{ marginRight: '5px' }} />
          }
          {showOnLine ? t('hide_your_last_seen') : t('show_your_last_seen')}
        </MenuItem>
      </Menu>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
        <Alert onClose={handleCloseSnack} severity={"success"} sx={{ width: '100%' }}>
          {showOnLine ?
            t('sharing_your_last_connection_has_been_successfully_activated')
            :
            t('sharing_your_last_login_has_been_successfully_disabled')
          }
        </Alert>
      </Snackbar>

    </div>
  )
}

export default OneToOne