import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { api } from '../../../api/api'
import ButtonSubmit from '../../../components/ButtonSubmit/ButtonSubmit'
import TextInput from '../../../components/TextInput/TextInput'
import './ForgetPassword.style.css'
import { IoMailUnreadOutline } from 'react-icons/io5'
import { BsShieldLock } from 'react-icons/bs'
import { BiLock } from 'react-icons/bi'
import { ValidateEmail } from '../../../hooks/Utils/parsing'
import { color } from '../../../hooks/Utils/color'



const ForgetPassword = () => {

    /* STATE */
    const { t } = useTranslation()
    const navigate = useNavigate()
    const language = localStorage.getItem('language')
    const [isLoading, setIsloading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [code, setCode] = useState('')
    const [error, setError] = useState('')
    const [screen, setScreen] = useState(0)


    /* HANDLER */
    const handleResetPassword = async (e) => {
        try {
            e.preventDefault();
            if (!ValidateEmail(email)) {
                setError(t('invalid_email'))
                return
            }
            setError('')
            setIsloading(true)
            const request = await api('api/user/reset-password', 'POST', {
                user_email: email.toLowerCase(),
            })
            const response = await request.json()
            console.log(response, 'Response reset password:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setScreen(1)
                } else {
                    setError(t('this_email_does_not_have_an_account'))
                }
            } else {
                setError(t('this_email_does_not_have_an_account'))
            }
            setIsloading(false)
        } catch (error) {
            setIsloading(false)
            setError(t('no_internet_connection'))
            console.warn('Error reset password', error.message);
        }
    }


    const handleConfirmationCode = async (e) => {
        try {
            e.preventDefault();
            if (code.length < 4) {
                setError(t('invalid_confirmation_code'))
                return
            }
            setError('')
            setIsloading(true)
            const request = await api(`api/password/${code}`, 'GET', null)
            const response = await request.json()
            console.log('Response confirmation code:', response)
            if (request.ok && request.status === 200) {
                if (response.success && response.email === email?.toLowerCase()) {
                    setScreen(2)
                } else {
                    setError(t('invalid_confirmation_code'))
                }
            } else {
                setError(t('invalid_confirmation_code'))
            }
            setIsloading(false)
        } catch (error) {
            setIsloading(false)
            setError(t('no_internet_connection'))
            console.warn('Error confirmation code', error.message);
        }
    }


    const handleUpdatePassword = async (e) => {
        try {
            e.preventDefault();
            if (password.length < 8) {
                setError(t('invalid_password'))
                return
            }
            setError('')
            setIsloading(true)
            const body = {
                user_email: email.toLowerCase(),
                user_password: password,
            }
            const request = await api('api/password/update', 'POST', body)
            const response = await request.json()
            console.log('Response update password password:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    navigate('/login', { replace: true })
                }
            } else {
                setError(t('an_error_occurred_please_try_again_later'))
            }
            setIsloading(false)
        } catch (error) {
            setIsloading(false)
            setError(t('no_internet_connection'))
            console.warn('Error update password password', error.message);
        }
    }


    if (screen === 0) {
        return (
            <div className='forget-password'>
                <div className='card-form'>

                    <img src={require('assets/app/icon_socialhub.png')}
                        style={{
                            height: '150px',
                            marginTop: '20px',
                            marginBottom: '30px'
                        }}
                        alt=''
                    />

                    <h3 style={{ fontWeight: 'bold' }} className='title'>
                        {t('forgot_password')}
                    </h3>

                    <span className='subtitle'>
                        {t('forgot_your_password')}
                    </span>
                    <br></br>
                    <span className='subtitle'>
                        {t('please_enter_your_account_email_below_to_reset_it')}
                    </span>
                    <div className='error-message'>
                        {error}
                    </div>
                    <form onSubmit={handleResetPassword}>
                        <TextInput
                            value={email}
                            setValue={setEmail}
                            type='email'
                            placeholder={t('email')}
                            style={{ marginBottom: '10%' }}
                        />

                        <ButtonSubmit
                            value={t('continue')}
                            isLoading={isLoading}
                            loadBoostrapColor={'light'}
                            style={{
                                width: '100%',
                                color: 'white',
                                marginBottom: '5%',
                                backgroundColor: color.primary
                            }}
                        />

                    </form>

                </div>
            </div>
        )
    }

    if (screen === 1) {
        return (
            <div className='forget-password'>
                <div className='card-form'>

                    <img src={require('assets/app/icon_socialhub.png')}
                        style={{
                            // width: '200px',
                            height: '150px',
                            marginTop: '20px',
                            marginBottom: '30px'
                        }}
                        alt=''
                    />

                    <h3 style={{ fontWeight: 'bold' }} className='title'>
                        {t('account_verification')}
                    </h3>

                    <span className='subtitle'>
                        {t('a_6_digit_code_has_just_been_sent')} <b>{email}</b> {t('and_the_telephone_number_associated_with_the_account')}.
                    </span>
                    <br></br>
                    <span className='subtitle' style={{ fontSize: '14px' }}>
                        {t('if_you_dont_see_the_email_look_in_your_spam')}
                    </span>
                    <div className='error-message'>
                        {error}
                    </div>
                    <form onSubmit={handleConfirmationCode}>
                        <TextInput
                            value={code}
                            setValue={setCode}
                            type='number'
                            placeholder={t('confirmation_code')}
                            style={{ textAlign: 'center', marginBottom: '10%' }}
                        />

                        <ButtonSubmit
                            value={t('confirm')}
                            isLoading={isLoading}
                            loadBoostrapColor={'light'}
                            style={{
                                width: '100%',
                                color: 'white',
                                marginBottom: '5%',
                                backgroundColor: color.primary
                            }}
                        />

                    </form>

                </div>
            </div>
        )
    }


    if (screen === 2) {
        return (
            <div className='forget-password'>

                <div className='card-form mb-4'>

                    <img src={require('assets/app/icon_socialhub.png')}
                        style={{
                            height: '150px',
                            marginTop: '20px',
                            marginBottom: '30px'
                        }}
                        alt=''
                    />

                    <h3 style={{ fontWeight: 'bold' }} className='title'>
                        {t('password_reset')}
                    </h3>

                    <span className='subtitle'>
                        {t('enter_your_new_password')}
                    </span>

                    <div className='error-message'>
                        {error}
                    </div>

                    <form onSubmit={handleUpdatePassword}>
                        <TextInput
                            value={password}
                            setValue={setPassword}
                            type='password'
                            placeholder={t('new_password')}
                            style={{ textAlign: 'center', marginBottom: '10%' }}
                        />

                        <ButtonSubmit
                            value={t('reset')}
                            isLoading={isLoading}
                            loadBoostrapColor={'light'}
                            style={{
                                width: '100%',
                                color: 'white',
                                marginBottom: '5%',
                                backgroundColor: color.primary
                            }}
                        />

                    </form>

                </div>
            </div>
        )
    }

}

export default ForgetPassword