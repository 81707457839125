import React, { memo, useCallback, useState } from 'react';
import styles from './DropdownRequestType.module.css'
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useAccessToken } from '../../hooks/ReducerHooks/ReducerHooks';
import { api } from '../../api/api';
import { useEffect } from 'react';
import { color } from '../../hooks/Utils/color';

const DropdownRequestType = ({
    title = '',
    value = '',
    setValue = () => null,
    containerStyle
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const controller = new AbortController();
    const signal = controller.signal;

    // INPUT STATE
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({value: value, label: value});
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const request = await api(`api/rh/hr_request_types`, 'GET', {}, accessToken, signal)
                const response = await request.json()
                // console.log('Fetch rh data response:', response)
                setIsLoading(false)
                if (request?.ok && request?.status === 200) {
                    const formattedOptions = response.map((item) => ({
                        value: item?.hr_req_type_id,
                        label: item.hr_req_type_name,
                    }));
                    setOptions(formattedOptions);
                    setSelectedOption(formattedOptions[0])
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Error fetching options:', error);
            }
        }
        fetchData()
        return () => {
            controller.abort()
        }
    }, [])


    return (
        <div className={`${styles.container} ${containerStyle}`}>

            {title && <span className={styles.titleInput}>{title}</span>}
            <div className={styles.selectInput}>
                <Select
                placeholder={title}
                    defaultValue={selectedOption}
                    onChange={(newValue) => {
                        setSelectedOption(newValue)
                        setValue(newValue?.value)
                    }}
                    options={options}
                    isSearchable={true}
                    isLoading={isLoading}
                    isClearable
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isFocused ? `1px solid #01195f` : "1px solid #cccccc",
                            boxShadow: "none",
                            "&:hover": {
                                border: "1px solid #01195f",
                                boxShadow: "none"
                            }
                        }),
                    }}
                />
            </div>
        </div>
    )
};

export default memo(DropdownRequestType)