import { useNavigate } from 'react-router-dom'
import styles from './InMail.module.css'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import { InMailKeys } from 'queries/QueryKeys'
import { fetchInMails } from 'queries/InMailQueries'
import { useInfiniteQuery } from 'react-query'
import InfiniteScroll from 'react-infinite-scroll-component'
import InMailCard from './InMailCard/InMailCard'
import { Spinner } from 'react-bootstrap'
import { color } from 'hooks/Utils/color'
import { IoMdMail } from "react-icons/io";


const InMail = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const [isEmpty, setEmpty] = useState(false)

    const goToNewInMail = () => navigate('/in-mail/new', {
        state: {
            in_mail: null,
            type: 'new_in_mail'
        }
    })
    const goToOneToOne = () => navigate('/one-to-one')

    const {
        data,
        isFetching,
        isLoading,
        isError,
        hasMore,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: InMailKeys.InMail,
        queryFn: ({ pageParam }) => fetchInMails(pageParam, accessToken),
        getPreviousPageParam: firstPage => firstPage?.prevPage ?? undefined,
        getNextPageParam: (lastPage) => lastPage.nextPage,
        refetchOnWindowFocus: true
    });

    const inMails = data?.pages.flatMap(page =>
        page.data
    );

    // CHECK IF IS EMPTY
    useEffect(() => {
        if (inMails?.length === 0) {
            setEmpty(true)
        } else {
            setEmpty(false)
        }
    }, [inMails])



    // LOADING SCREEN
    if (isLoading) {
        return (
            <div className={styles.page}>

                {/* TAB */}
                <div className={styles.tab}>
                    <p onClick={goToOneToOne} className={styles.tablabel}>
                        Inbox
                    </p>
                    <p className={styles.tablabel} style={{ color: color.primary }}>
                        InMail
                    </p>
                </div>

                {/* HEADER */}
                <div className={styles.header}>
                    <h3 className={styles.headerTitle}>
                        InMail
                    </h3>
                    <button onClick={goToNewInMail} className={styles.headerButton}>
                        Nouveau
                    </button>
                </div>
                <div className={styles.loadingContainer}>
                    <div className={styles.spinnerContainer}>
                        <Spinner variant='secondary' />
                    </div>
                    <p className={styles.loadingText}>
                        {t('loading')}
                    </p>
                </div>
            </div>
        )
    }



    // LOADING SCREEN
    if (isEmpty) {
        return (
            <div className={styles.page}>

                {/* TAB */}
                <div className={styles.tab}>
                    <p onClick={goToOneToOne} className={styles.tablabel}>
                        Inbox
                    </p>
                    <p className={styles.tablabel} style={{ color: color.primary }}>
                        InMail
                    </p>
                </div>

                {/* HEADER */}
                <div className={styles.header}>
                    <h3 className={styles.headerTitle}>
                        InMail
                    </h3>
                    <button onClick={goToNewInMail} className={styles.headerButton}>
                        Nouveau
                    </button>
                </div>
                <div className={styles.loadingContainer}>
                    <div className={styles.spinnerContainer}>
                        <IoMdMail color='#c0c0c0' size={70} />
                    </div>
                    <p className={styles.loadingText}
                        style={{
                            color: '#c0c0c0',
                            fontWeight: '500'
                        }}>
                        {i18n.language === 'en'
                            ? 'No InMail available'
                            : 'Aucun  InMail disponible'
                        }
                    </p>
                </div>
            </div>
        )
    }



    return (
        <div className={styles.page}>

            {/* TAB */}
            <div className={styles.tab}>
                <p onClick={goToOneToOne} className={styles.tablabel}>
                    Inbox
                </p>
                <p className={styles.tablabel} style={{ color: color.primary }}>
                    InMail
                </p>
            </div>

            {/* HEADER */}
            <div className={styles.header}>
                <h3 className={styles.headerTitle}>
                    InMail
                </h3>
                <button onClick={goToNewInMail} className={styles.headerButton}>
                    Nouveau
                </button>
            </div>

            {/* IN-MAIL LIST */}
            <div id="scrollableContent" className={styles.contentContainer}>
                <InfiniteScroll
                    dataLength={inMails?.length || 0}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    scrollableTarget="scrollableContent"
                    scrollThreshold={0.9}
                    style={{ overflow: 'hidden' }}>
                    {inMails?.map((item, index) => {
                        return (
                            <div key={index?.toString()}>
                                <InMailCard
                                    inMail={item}
                                />
                            </div>
                        )
                    })}
                    {hasNextPage &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '5px'
                        }}>
                            <Spinner
                                variant='secondary'
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default InMail