import React, { memo, useEffect, useState } from 'react'
import SondVideo from '../OpinionBody/SondVideo/SondVideo'
import { color } from 'hooks/Utils/color'

const SondAttachment = ({
    attachment,
    width = 100,
    height = '400px',
    opinion = null,
}) => {



    const RenderAttachment = () => {
        if (attachment?.sond_type === "image") {
            if (opinion?.sond_activity === 'UPDATE_PROFILE_PICTURE') {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                            src={attachment?.sond_picture}
                            alt=''
                            style={{
                                width: '350px',
                                height: '350px',
                                borderRadius: '300px',
                                border: `3px solid ${color.primary}`,
                                objectFit: 'cover'
                            }} />
                    </div>
                )
            } else {
                return (
                    <div style={{ backgroundColor: '#f1f1f1' }}>
                        <img
                            src={attachment?.sond_picture}
                            alt=''
                            style={{
                                width: `${width}%`,
                                height: height,
                                objectFit: 'cover',
                                border: '1px white solid'
                            }} />
                    </div>
                )
            }
        }

        if (attachment?.sond_type === "video") {
            return (
                <div style={{ width: `${width}%`, height: height, border: '1px white solid' }}>
                    <SondVideo
                        uri={attachment?.sond_picture}
                        thumbnail={attachment?.sond_thumbnail}
                        width={`${width}%`}
                        height={height}
                    />
                </div>
            )
        }
    }


    return (
        <div>
            {RenderAttachment()}
        </div>
    )
}

export default memo(SondAttachment)