import React, { memo, useEffect, useState } from 'react'
import './NavigationBottom.style.css'
import { Link, useLocation } from 'react-router-dom'
import { FaUsers } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { MdAddBox, MdGroups, MdOutlinePublic } from 'react-icons/md'
import { AiFillMessage } from 'react-icons/ai'
import { IoSearchSharp } from 'react-icons/io5'
import { color } from '../../hooks/Utils/color'
import { useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import ModalCreateOpinion from '../ModalCreateOpinion/ModalCreateOpinion'

const NavigationBottom = () => {

  const { t } = useTranslation()
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState('/Home')


  const { data: AuthData } = useAuthReducer()
  const [showAddOpinion, setShowAddOpinion] = useState(false)


  useEffect(() => {
    if (location?.pathname === '/') {
      setCurrentPage('home')
    }
    if (location?.pathname === '/home') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/public') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/one-to-one') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/agora') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
  }, [location])


  return (
    <div style={{
      display: location?.pathname?.includes('one-to-one/') ||
        location?.pathname?.includes('in-mail/new')
        ? 'none'
        : 'block'
    }}>
      <nav className="bottom-nav" style={{ backgroundColor: 'white' }}>
        <ul style={{ backgroundColor: 'white' }}>
          <li>
            <Link to={'/home'}>
              <div style={{ flexDirection: 'column' }}>
                <FaUsers
                  size={27}
                  color={
                    currentPage === 'home'
                      ? color.primary
                      : color.grayDark
                  }
                />
                <p style={{
                  fontWeight: '600',
                  fontSize: '10px',
                  color: currentPage === 'home'
                    ? color.primary
                    : color.grayDark
                }}>
                  {t('contacts')}
                </p>
              </div>
            </Link>
          </li>
          <li>

            <Link to={'/public'}>
              <div style={{ flexDirection: 'column' }}>
                <MdOutlinePublic
                  size={27}
                  color={
                    currentPage === 'public'
                      ? color.primary
                      : color.grayDark
                  }
                />
                <p style={{
                  fontWeight: '600',
                  fontSize: '10px',
                  color: currentPage === 'public'
                    ? color.primary
                    : color.grayDark
                }}>
                  {t('public_posts')}
                </p>
              </div>
            </Link>
          </li>

          <li>
            <Link onClick={() => setShowAddOpinion(true)}>
              <div style={{ flexDirection: 'column' }}>
                <MdAddBox
                  size={27}
                  color={color.grayDark}
                />
                <p style={{
                  fontWeight: '600',
                  fontSize: '10px',
                  color: color.grayDark
                }}>
                  {t('publish')}
                </p>
              </div>
            </Link>
          </li>

          <li>
            <Link to={'/one-to-one'}>
              <div>
                <AiFillMessage
                  size={27}
                  color={
                    currentPage === 'one-to-one'
                      ? color.primary
                      : color.grayDark
                  }
                />
                <p style={{
                  fontWeight: '600',
                  fontSize: '10px',
                  color: currentPage === 'one-to-one'
                    ? color.primary
                    : color.grayDark
                }}>
                  {t('messaging')}
                </p>
              </div>
            </Link>
          </li>

          <li>

            <Link to={'/agora'}>
              <div style={{ flexDirection: 'column' }}>
                <MdGroups
                  size={27}
                  color={
                    currentPage === 'agora'
                      ? color.primary
                      : color.grayDark
                  }
                />
                <p style={{
                  fontWeight: '600',
                  fontSize: '10px',
                  color: currentPage === 'agora'
                    ? color.primary
                    : color.grayDark
                }}>
                  {t('groups')}
                </p>
              </div>
            </Link>
          </li>

        </ul>

      </nav>

      <ModalCreateOpinion
        user={AuthData?.user}
        show={showAddOpinion}
        setShow={setShowAddOpinion}
      />
    </div>
  )
}

export default memo(NavigationBottom)