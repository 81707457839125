import React, { useEffect, useState } from 'react'
import styles from './InMailNew.module.css'
import Header from 'components/Header/Header'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { api } from 'api/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap'
import { IoIosCloseCircle } from "react-icons/io";
import { Alert } from '@mui/material';
import axios from 'axios'
import { useQueryClient } from 'react-query'
import { InMailKeys } from 'queries/QueryKeys'
import InMailMessageCard from '../InMailDetails/InMailMessageCard/InMailMessageCard'
import { FaPaperclip } from "react-icons/fa";
import { color } from 'hooks/Utils/color'
import InMailAttachCard from '../Componants/InMailAttachCard/InMailAttachCard'

const InMailNew = () => {


    const API_URL = process.env.REACT_APP_API_URL

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const location = useLocation();
    const {
        in_mail,
        type
    } = location.state

    const queryClient = useQueryClient()

    const draft = localStorage.getItem('in_mail_draft')

    const [title, setTitle] = useState(draft?.title || '')
    const [message, setMessage] = useState(draft?.message || '')

    const [receiver, setReceiver] = useState(null)
    const [receiverCCList, setReceiverCCList] = useState(draft?.receiver_cc || [])
    const [receiverCCIList, setReceiverCCIList] = useState(draft?.receiver_cci || [])
    const [attachments, setAttachments] = useState(draft?.attachments || [])

    const [search, setSearch] = useState('')
    const [searchCC, setSearchTextCC] = useState('')
    const [searchCCI, setSearchTextCCI] = useState('')

    const [suggestions, setSuggestions] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(false)

    const [searchText, setSearchText] = useState('')

    const [isSending, setIsSending] = useState(false)
    const [progress, setProgress] = useState(0)


    const [isSearching, setIsSearching] = useState(false)
    const [isSearchingCC, setIsSearchingCC] = useState(false)
    const [isSearchingCCI, setIsSearchingCCI] = useState(false)

    const [isSearchingMore, setIsSearchingMore] = useState(false)

    const [isFocused, setIsFocused] = useState(false)
    const [isFocusedCC, setIsFocusedCC] = useState(false)
    const [isFocusedCCI, setIsFocusedCCI] = useState(false)

    const [error, setError] = useState(null)

    const goBack = () => {
        saveDraft()
        navigate(-1)
    }


    // INIT DATA
    useEffect(() => {
        if (in_mail && type === 'forward') {
            setTitle(in_mail?.in_mail_title)
        }
        if (in_mail && type !== 'forward') {

            setTitle(in_mail?.in_mail_title)

            setReceiver({
                id: in_mail?.receiver?.user_id,
                name: `${in_mail?.receiver?.user_surname || ''} ${in_mail?.receiver?.user_name || ''}`,
                picture: in_mail?.receiver?.profile?.prof_picture
            })

            if (in_mail?.cc?.length !== 0) {
                const new_cc = in_mail?.cc?.map(item => ({
                    id: item?.user_id,
                    name: `${item?.user_surname || ''} ${item?.user_name || ''}`,
                    picture: item?.profile?.prof_picture,
                    replied: true
                }))
                setReceiverCCList(new_cc)
            }

            if (in_mail?.cci?.length !== 0) {
                const new_cci = in_mail?.cci?.map(item => ({
                    id: item?.user_id,
                    name: `${item?.user_surname || ''} ${item?.user_name || ''}`,
                    picture: item?.profile?.prof_picture,
                    replied: true
                }))
                setReceiverCCIList(new_cci)
            }
        }
    }, [])



    const saveDraft = () => {
        try {
            const new_daft = {
                title: title,
                message: message,
                receiver: receiver,
                receiver_cc: receiverCCList,
                receiver_cci: receiverCCIList,
                attachments: attachments,
                // in_mail: in_mail,
                // type: type
            }
            localStorage.setItem(
                'in_mail_draft',
                new_daft
            )
        } catch (error) {
            console.error('Error save draft:', error);
        }
    }



    //  MENTIONNE SEARCH 
    useEffect(() => {
        if (isFocused) {
            setSearchText(search)
        } else if (isFocusedCC) {
            setSearchText(searchCC)
        } else if (isFocusedCCI) {
            setSearchText(searchCCI)
        }
    }, [search, searchCC, searchCCI])

    useEffect(() => {
        let searchTimerId
        clearTimeout(searchTimerId);
        searchTimerId = setTimeout(() => {
            fetchSuggestion()
        }, 500);
        return () => {
            clearTimeout(searchTimerId);
        }
    }, [searchText])

    const fetchSuggestion = async () => {
        try {
            if (searchText?.length >= 3) {
                setIsSearching(true)
                const request = await api(
                    `api/search/tag?search=${searchText}&page=1`,
                    'GET',
                    {},
                    accessToken
                )
                const response = await request.json()
                setIsSearching(false)
                // console.log('Response mention search:', response)
                // console.log('Response mention search', response?.next_page_url)
                if (request.status === 200) {
                    if (response?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    const list = response?.data?.filter(item => item?.type !== 'agora')
                    setSuggestions(list)
                    setPage(2)

                }
            }
        } catch ({ message }) {
            setIsSearching(false)
            throw new Error(message)
        }
    }

    const loadMoreSuggestion = async () => {
        try {
            if (searchText?.length >= 3 && hasMore) {
                setIsSearchingMore(true)
                const request = await api(`api/search/tag?search=${searchText}&page=${page}`, 'GET', {}, accessToken)
                const response = await request.json()
                setIsSearchingMore(false)
                // console.log('Response mention search', response?.current_page)
                if (request.status === 200) {
                    if (response?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    setPage(page => page + 1)
                    const list = response?.data?.filter(item => item?.type !== 'agora')
                    setSuggestions(prevState => [...prevState, ...list])
                }
            }
        } catch ({ message }) {
            setIsSearchingMore(false)
            throw new Error(message)
        }
    }



    const handleSelectReceiver = (item) => {
        if (
            !receiverCCList?.some(data => data?.id === item?.id) &&
            receiver?.id !== item?.id
        ) {
            if (search?.length > 3) {
                setReceiver(item)
            } else if (searchCC?.length > 3) {
                setReceiverCCList(value => [...value, item])
            } else if (searchCCI?.length > 3) {
                setReceiverCCIList(value => [...value, item])
            }
        }

        setSearchText('')
        setSearch('')
        setSearchTextCC('')
        setSearchTextCCI('')

        setPage(1)

        setSuggestions([])

    }

    const handleRemoveReceiver = (type, item) => {

        if (type === 'receiver') {
            setReceiver(null)
        } else if (type === 'cc') {
            const new_list = receiverCCList?.filter(data => data?.id !== item?.id)
            setReceiverCCList(new_list)
        } else if (type === 'cci') {
            const new_list = receiverCCIList?.filter(data => data?.id !== item?.id)
            setReceiverCCIList(new_list)
        }

        setSearchText('')
        setSearch('')
        setSearchTextCC('')
        setSearchTextCCI('')

        setPage(1)

        setSuggestions([])
    }





    const handleSendMessage = async () => {
        try {
            //  in_mail_parent_id: int
            //  in_mail_receiver_id: int
            //  in_mail_title: string
            //  in_mail_message: string
            //  cc: array<user_id>
            //  cci: array<user_id>
            //  attachments: array<files>
            setError(null)
            if (!receiver?.id) {
                setError(
                    i18n.language === 'en'
                        ? 'Please add a corresponding.'
                        : 'Veuillez ajouter un correspondant.'
                )
                return
            }

            if (!title) {
                setError(
                    i18n.language === 'en'
                        ? 'Please enter the subject of your InMail.'
                        : 'Veuillez entrer l\'object de votre InMail.'
                )
                return
            }

            if (!message) {
                setError(
                    i18n.language === 'en'
                        ? 'Please enter your message.'
                        : 'Veuillez entrer votre message.'
                )
                return
            }

            setIsSending(true)


            const formData = new FormData();
            formData.append('in_mail_title', title);
            formData.append('in_mail_message', message);
            formData.append('in_mail_receiver_id', receiver?.id);
            if (in_mail?.in_mail_id) {
                formData.append('in_mail_parent_id', in_mail?.in_mail_id);
            }
            formData.append(`cc`, JSON.stringify(receiverCCList?.map(item => item?.id)))

            if (receiverCCIList?.length !== 0) {
                formData.append('cci', JSON.stringify(receiverCCIList?.map(item => item?.id)));
            }

            attachments?.forEach((attach, index) => {

                formData.append(`attach_filename_${index + 1}`, attach?.name)
                formData.append(`attachment_type_${index + 1}`, attach?.type)
                formData.append(`attachment_extension_${index + 1}`, attach?.extension)

                formData.append(`attachment_${index + 1}`, attach?.file);

            });
            const response = await axios.post(`${API_URL}/api/in_mail`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                },
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent
                    setProgress(Math.round((loaded / total) * 100))
                    // console.log(Math.round((loaded / total) * 100), '% envoi...')
                },
            })
            setProgress(0)
            setIsSending(false)
            // console.log('Send Inmail response', response?.data?.data)
            if (response.status === 200) {
                if (response?.data?.success) {
                    // addInMailFromQuery(response?.data?.data)
                    goBack()
                } else {
                    setError(
                        i18n.language === 'en'
                            ? 'We encountered an error, please try again.'
                            : 'Nous avons rencontré une erreur, veuillez réessayer.'
                    )
                }
            } else {
                setError(
                    i18n.language === 'en'
                        ? 'We encountered an error, please try again.'
                        : 'Nous avons rencontré une erreur, veuillez réessayer.'
                )
            }
        } catch (error) {
            setIsSending(false)
            console.error(error?.response?.data || error);
            setError(
                i18n.language === 'en'
                    ? 'We encountered an error, please try again.'
                    : 'Nous avons rencontré une erreur, veuillez réessayer.'
            )
        }
    }



    const addInMailFromQuery = async (data) => {
        try {
            const queryData = queryClient.getQueryData(InMailKeys.InMail)
            const newPagesArray = queryData?.pages?.map((page, index) =>
                index === 0
                    ? {
                        data: [data, ...page?.data],
                        nextPage: page?.data?.nextPage,
                        prevPage: page?.data?.prevPage
                    }
                    : page
            )
            queryClient.setQueryData(InMailKeys.InMail, () => ({
                pages: newPagesArray,
                pageParams: queryData?.pageParams,
            }))
        } catch (error) {
            console.error(error);
        }
    }


    const handleSelectAttach = (e) => {
        try {
            if (e.target.files) {
                const file = e.target.files[0]
                const uri = window.URL.createObjectURL(file)

                const data = {
                    file,
                    name: file?.name,
                    uri,
                    size: file?.size,
                    type: file?.type?.split("/")[0],
                    extension: file?.name?.split('.').pop(),
                    mime_type: file?.type,

                    attach_filename: file?.name,
                    attach_extension: file?.name?.split('.').pop()
                }
                setAttachments(attachments => [...attachments, data])
            }
        } catch (error) {
            console.error(error);
        }
    }




    const renderRecipient = (type, item) => {
        return (
            <div
                className={styles.recipientItem}>
                <img src={item?.picture
                    ? item?.picture
                    : require('assets/images/background_opinion.jpg')
                }
                    width={30}
                    height={30}
                    alt=''
                    style={{
                        borderRadius: '30px',
                        marginRight: '5px'
                    }}
                />
                <p className={styles.recipientName}>
                    {item?.name}
                </p>
                <button onClick={() => handleRemoveReceiver(type, item)} className={styles.recipientButton}>
                    <IoIosCloseCircle color='red' />
                </button>
            </div>
        )
    }

    return (
        <div className={styles.page}>
            <Header
                title={i18n.language === 'en'
                    ? 'New message'
                    : 'Nouveau message'}
                goBack={goBack}
            />
            <div className={styles.contentContainer}>
                {error &&
                    <Alert severity="error"
                        style={{
                            marginTop: '5px',
                            marginBottom: '5px'
                        }}>
                        {error}
                    </Alert>
                }
                <div className={styles.recipientInputContainer}>
                    <span className={styles.recipientLabel}>À</span>
                    <div style={{ width: '95%', display: 'flex', justifyContent: 'space-between' }}>
                        {receiver ?
                            renderRecipient('receiver', receiver)
                            :
                            <input
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                onFocus={() => {
                                    setIsFocused(true)
                                    setIsFocusedCC(false)
                                    setIsFocusedCCI(false)
                                }}
                                onBlur={() => {
                                    setIsFocused(false)
                                }}
                                placeholder='Destinataire'
                                className={styles.recipientInput}
                            />
                        }
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                            <label onChange={(e) => handleSelectAttach(e)}
                                htmlFor="formId"
                                className={styles.attachButton}>
                                <input accept="*/*" name="" type="file" id="formId" hidden />
                                <FaPaperclip
                                    size={28}
                                    color={color.primary}
                                />
                            </label>

                            <button onClick={handleSendMessage} className={styles.sendButton}>
                                {!isSending ?
                                    i18n.language === 'en'
                                        ? 'Send'
                                        : 'Envoyer'
                                    :
                                    <Spinner variant='white' size='sm' />
                                }
                            </button>
                        </div>
                    </div>
                </div>

                <div className={styles.recipientInputContainer}>
                    <span className={styles.recipientLabel}>Cc</span>
                    <div className={styles.recipientContainer}>
                        {receiverCCList?.length !== 0 &&
                            receiverCCList?.map(item => renderRecipient('cc', item))
                        }
                        <input
                            value={searchCC}
                            onChange={e => setSearchTextCC(e.target.value)}
                            onFocus={() => {
                                setIsFocused(false)
                                setIsFocusedCC(true)
                                setIsFocusedCCI(false)
                            }}
                            onBlur={() => {
                                setIsFocusedCC(false)
                            }}
                            placeholder='Destinataires'
                            className={styles.recipientInput}
                        />
                    </div>
                </div>

                <div className={styles.recipientInputContainer}>
                    <span className={styles.recipientLabel}>Cci</span>
                    <div className={styles.recipientContainer}>
                        {receiverCCIList?.length !== 0 &&
                            receiverCCIList?.map(item => renderRecipient('cci', item))
                        }
                        <input
                            value={searchCCI}
                            onChange={e => setSearchTextCCI(e.target.value)}
                            onFocus={() => {
                                setIsFocused(false)
                                setIsFocusedCC(false)
                                setIsFocusedCCI(true)
                            }}
                            onBlur={() => {
                                setIsFocusedCCI(true)
                            }}
                            placeholder='Destinataires'
                            className={styles.recipientInput}
                        />
                    </div>
                </div>

                <div className={styles.recipientInputContainer}>
                    <input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        disabled={type == 'reply'}
                        placeholder='Object du message'
                        className={styles.subjectInput}
                    />
                </div>
                <div className={
                    searchText?.length > 3 &&
                        suggestions?.length !== 0
                        ? styles.messageInputContainerHide
                        : styles.messageInputContainer
                }>
                    <textarea
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        placeholder='Rédigez votre message'
                        cols="40"
                        rows="7"
                        className={styles.messageInput}>
                    </textarea>
                    {/*    file,
                    uri,
                    size: file?.size,
                    type: file?.type?.split("/")[0],
                    extension: file?.name?.split('.').pop(),
                    mime_type: file?.type */}

                    <InMailAttachCard attachments={attachments} />

                    <div style={{ marginBottom: '80px' }}>
                        {in_mail &&
                            <InMailMessageCard message={in_mail} />
                        }
                    </div>
                </div>

                <div id='scrollableContent'
                    className={
                        searchText?.length > 3 &&
                            suggestions?.length !== 0
                            ? styles.suggestionContainer
                            : styles.suggestionContainerHide
                    }>
                    <InfiniteScroll
                        dataLength={suggestions?.length || 0}
                        next={loadMoreSuggestion}
                        hasMore={hasMore}
                        scrollableTarget="scrollableContent"
                        scrollThreshold={0.9}
                        style={{ overflow: 'hidden' }}>
                        {suggestions?.map((item, index) => {
                            return (
                                <div onClick={() => handleSelectReceiver(item)}
                                    key={index?.toString()}
                                    className={styles.suggestionItem}>
                                    <img src={item?.picture
                                        ? item?.picture
                                        : require('assets/images/background_opinion.jpg')
                                    }
                                        width={50} alt=''
                                        style={{
                                            borderRadius: '50px',
                                            marginRight: '10px'
                                        }}
                                    />
                                    <div>
                                        <p className={styles.suggestionName}>
                                            {item?.name}
                                        </p>
                                        <p className={styles.suggestionUsename}>
                                            {item?.username}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                        {isSearching &&
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: '5px'
                            }}>
                                <Spinner
                                    variant='secondary'
                                />
                            </div>
                        }
                    </InfiniteScroll>

                </div>
            </div>
        </div>
    )
}

export default InMailNew