import React, { memo } from 'react'
import styles from './InMailMessageCard.module.css'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import InMailAttachCard from '../../Componants/InMailAttachCard/InMailAttachCard'

const InMailMessageCard = ({
    message
}) => {

    const { t, i18n } = useTranslation()

    return (
        <div className={styles.container}>
            <div
                className={styles.senderContainer}>
                <img src={message?.sender?.profile?.prof_picture
                    ? message?.sender?.profile?.prof_picture
                    : require('assets/images/background_opinion.jpg')
                }
                    width={40}
                    height={40}
                    alt=''
                    style={{
                        borderRadius: '40px',
                        marginRight: '15px'
                    }}
                />
                <div>
                    <p className={styles.senderName}>
                        {message?.sender?.user_surname || ''} {message?.sender?.user_name || ''}
                    </p>
                    <p className={styles.senderEmail}>
                        {message?.sender?.user_email}
                    </p>
                </div>

            </div>
            <p className={styles.date}>
                {i18n.language === 'en'
                    ? 'Sent on'
                    : 'Envoyé le'
                } {dayjs(message?.in_mail_sent_at).locale(i18n.language).format('DD MMM YYYY HH:mm')}
            </p>

            <p className={styles.message} style={{ whiteSpace: 'pre-line' }}>
                {message?.in_mail_message}
            </p>

            {message?.forwarded_inmail &&
                <div style={{ marginTop: '20px', marginBottom: '20px', }}>
                    <p style={{
                        fontWeight: '600',
                        fontSize: 15,
                        marginBottom: 4,
                    }}>
                        {i18n.language == 'en'
                            ? 'Message forwarded'
                            : 'Message transféré'
                        }
                    </p>

                    <p style={{
                        fontWeight: '500',
                        fontSize: 12,
                        color: '#969696',
                        margin: '0px'
                    }}>
                        De: {message?.forwarded_inmail?.sender?.user_email}
                    </p>

                    <p style={{
                        fontSize: 12,
                        fontWeight: '500',
                        color: '#969696',
                        margin: '0px'
                    }}>
                        {
                            i18n.language === 'en'
                                ? 'on'
                                : 'le'
                        } {dayjs(message?.forwarded_inmail?.in_mail_sent_at).locale(i18n.language).format('DD MMM YYYY HH:mm')}
                    </p>

                    <p style={{
                        fontWeight: '500',
                        fontSize: 12,
                        color: '#969696',
                        margin: '0px'
                    }}>
                        {i18n.language === 'en' ? 'Object' : 'Objet'}: {message?.forwarded_inmail?.in_mail_title}
                    </p>

                    <p style={{
                        fontSize: 12,
                        fontWeight: '500',
                        color: '#969696',
                        margin: '0px'
                    }}>
                        {i18n.language === 'en'
                            ? 'Sent to'
                            : 'Envoyé à'
                        } {message?.forwarded_inmail?.receiver?.user_surname || ''} {message?.forwarded_inmail?.receiver?.user_name || ''} ({message?.forwarded_inmail?.receiver?.user_email})
                    </p>

                    {(message?.forwarded_inmail?.cc && message?.forwarded_inmail?.cc?.length !== 0) &&
                        <div style={{ display: 'flex' }}>
                            <p style={{
                                fontWeight: '500',
                                color: '#969696',
                                margin: '0px'
                            }}>
                                Cc:
                            </p>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {message?.forwarded_inmail?.cc?.map((item, index) => {
                                    return (
                                        <p key={index?.toString()} style={{ fontSize: 12, fontWeight: '500', color: '#969696', marginTop: 3, marginHorizontal: 5 }}>
                                            {item?.user_surname || ''} {item?.user_name || ''} ({item?.user_email})
                                        </p>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            }

            {/* RENDER ATTACHMENTS */}
            {message?.attachments?.length !== 0 &&
                <InMailAttachCard attachments={message?.attachments} />
            }

        </div>
    )
}

export default InMailMessageCard