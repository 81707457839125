import React, { memo, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonSubmit from '../ButtonSubmit/ButtonSubmit'
import Header from '../Header/Header'
import TextInput from '../TextInput/TextInput'
import Select from 'react-select'
import { api } from '../../api/api'
import { ValidateEmail, ValidatePseudo } from '../../hooks/Utils/parsing'
import { useAccessToken } from '../../hooks/ReducerHooks/ReducerHooks'
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction'
import i18next from 'i18next'
import { color } from '../../hooks/Utils/color'


const ModalEditProfile = ({
    user,
    show = false,
    setShow,
    refresh
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()

    const [prof_title, setProf_title] = useState(user?.profile?.prof_title)
    const [prof_description, setProf_description] = useState(user?.profile?.prof_description)
    const [prof_email, setProf_email] = useState(user?.profile?.prof_email)
    const [prof_site_web, setProf_site_web] = useState(user?.profile?.prof_site_web)
    const [prof_phone, setProf_phone] = useState(user?.profile?.prof_phone)
    const [prof_birthday, setProf_birthday] = useState(user?.profile?.prof_birthday)

    const [user_surname, setUser_surname] = useState(user?.user_surname)
    const [user_name, setUser_name] = useState(user?.user_name)
    const [user_username, setUser_username] = useState(user?.user_username)
    const [user_civility, setUser_civility] = useState(user?.user_civility)
    const [user_ville, setUser_ville] = useState(user?.user_ville)
    const [user_pays, setUser_pays] = useState(user?.user_pays)
    const [user_email, setUser_email] = useState(user?.user_email)

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const [countries, setCountries] = useState([])

    const genders = [
        {
            value: 'M',
            label: 'Male',
        },
        {
            value: 'F',
            label: 'Female',
        },
        {
            value: 'A',
            label: 'Autre',
        },
    ]



    useEffect(() => {
        const getCountries = async () => {
            try {
                const request = await api('api/get-countries', 'GET', null)
                const response = await request.json()
                // console.log(response, 'responseeeee of get countries')
                if (request.ok && request.status === 200) {
                    const countries = response.countries.map(c => ({
                        label: c.French_Name,
                        value: c.Sort_Order,
                        key: c.ITU_T_Telephone_Code,
                    }))
                    setCountries(countries)
                }
            } catch (error) {
                getCountries()
                console.log('Erreur', error.message)
            }
        }
        getCountries()
    }, [])




    /* CHECK FORM */
    const checkForm = async (e) => {
        e.preventDefault();
        if (user_surname.trim().length < 3) {
            setError(t('your_surname_must_contain_at_least_4_characters'))
            return
        }
        if (!ValidatePseudo(user_username.trim())) {
            setError(t('your_nickname_must_contain_at_least_4_characters_only_underscore'))
            return
        }
        if (!ValidateEmail(user_email.trim())) {
            setError(t('invalid_email'))
            return
        }
        setError('')
        handleSubmit()
    }


    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const body = {
                prof_title,
                prof_description,
                prof_email,
                prof_site_web,
                user_civility,
                user_surname,
                user_name,
                user_username,
                user_ville,
                prof_phone,
                prof_birthday,
                user_email,
                user_pays: parseInt(user_pays),
                prof_country: parseInt(user_pays),
                prof_city: parseInt(user_ville)
            }

            const request = await api(`api/profiles/${user?.user_id}`, 'PUT', body, accessToken)
            const response = await request.json()
            console.log(response, 'response of profil edit ')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    refresh()
                    setShow(false)
                }
            } else {
                if (response?.error === 'The nickname is already taken') {
                    setError(t('the_nickname_is_already_taken'))
                } else {
                    setError('profilEdit:sorry')
                }
            }
            setIsLoading(false)
        } catch (response) {
            setIsLoading(false)
            console.log(response);
        }
    }


    return (
        <Modal scrollable={true} show={show}>

            <Header title={t('edit_profile')} type='modal' goBack={() => setShow(false)} />

            <span style={{ textAlign: 'center', color: 'red', marginTop: '10px' }}>
                {error}
            </span>

            <div style={{ paddingLeft: '15%', paddingRight: '15%', paddingTop: '3%', paddingBottom: '3%', overflowY: 'scroll' }}>

                <form onSubmit={checkForm} >

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                        {t('profile_title')}
                    </span>
                    <TextInput
                        value={prof_title}
                        setValue={setProf_title}
                        type='text'
                        placeholder={t('profile_title')}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                        {t('profile_description')}
                    </span>
                    <TextInput
                        value={prof_description}
                        setValue={setProf_description}
                        type='text'
                        placeholder={t('profile_description')}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                        {t('profile_email')}
                    </span>
                    <TextInput
                        value={prof_email}
                        setValue={setProf_email}
                        type='text'
                        placeholder={t('profile_email')}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                        {t('web_site')}
                    </span>
                    <TextInput
                        value={prof_site_web}
                        setValue={setProf_site_web}
                        type='text'
                        placeholder={t('web_site')}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />


                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                        {t('surname')}
                    </span>
                    <TextInput
                        value={user_surname}
                        setValue={setUser_surname}
                        type='text'
                        placeholder={`${t('surname')}`}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                        {t('name')}
                    </span>
                    <TextInput
                        value={user_name}
                        setValue={setUser_name}
                        type='text'
                        placeholder={`${t('name')}`}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                        {t('nickname')}
                    </span>
                    <TextInput
                        value={user_username}
                        setValue={setUser_username}
                        type='text'
                        placeholder={`${t('nickname')}`}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />


                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5%' }}>
                        <div style={{ width: '48%' }}>
                            <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                                {t('country')}
                            </span>
                            <Select
                                placeholder={t('country')}
                                options={countries}
                                defaultValue={countries.filter(item => item.value == user_pays)}
                                onChange={newValue => setUser_pays(newValue.value)}
                            />
                        </div>
                        <div style={{ width: '48%', }}>
                            <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                                {t('city')}
                            </span>
                            <TextInput
                                value={user_ville}
                                setValue={setUser_ville}
                                type='text'
                                placeholder={t('city')}
                                style={{ width: '100%' }}
                            />
                        </div>

                    </div>


                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                        {t('phone_number')}
                    </span>
                    <TextInput
                        value={prof_phone}
                        setValue={setProf_phone}
                        type='phone'
                        placeholder={t('phone_number')}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />


                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                        {t('civility')}
                    </span>
                    <div style={{ marginBottom: '5%' }}>
                        <Select
                            placeholder={t('civility')}
                            options={genders}
                            defaultValue={genders.filter(item => item.value == user_civility)}
                            isSearchable={false}
                            onChange={newValue => setUser_civility(newValue.value)}
                        />
                    </div>

                    <ButtonSubmit
                        value={t('update')}
                        isLoading={isLoading}
                        loadBoostrapColor={'light'}
                        style={{
                            width: '100%',
                            color: 'white',
                            marginBottom: '5%',
                            backgroundColor: '#1880a1'
                        }}
                    />

                </form>

            </div>

        </Modal>
    )
}

export default memo(ModalEditProfile)