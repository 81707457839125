import React, { memo } from 'react'
import Notifications from '../Notifications/Notifications'
import './Endbar.style.css'

const Endbar = () => {

    const { innerWidth: width, innerHeight: height } = window;

    return (
        <div className='endbar' style={{height: height}}>

            <div className='header-box'>
                <h5 className='title'>
                    Notifications
                </h5>
            </div>

            <div className='content-box'>
                <Notifications/>
            </div>

        </div>
    )
}

export default memo(Endbar)