const profileKeys = {
    userBalance: (user_id) => ['user-balance', user_id],
    userProfile: (user_id) => ['user-profile', user_id],
    opinionProfile: (user_id) => ['opinion-profile', user_id],
    buddiesYouKnow: (user_id) => ['fetchBuddiesYouKnow', user_id]
}

const homeQueryKeys = {
    home: ['Home']
}

const opinionKeys = {
    all: ['opinions'],
    lists: () => [...opinionKeys.all, 'list'],
    list: (filters) => [...opinionKeys.lists(), { filters }],
    details: () => [...opinionKeys.all, 'detail'],
    detail: (sond_id) => [...opinionKeys.details(), sond_id],
    comments: (sond_id) => [...opinionKeys.details(), sond_id, 'comment'],
    comment: (sond_id, comment_id) => [...opinionKeys.details(), sond_id, 'comment', comment_id],
    boostedOpinions: (sourceFrom) => ["boosted-opinions", sourceFrom],
    sponsoredOpinions: (sourceFrom) => ["sponsored-opinions", sourceFrom]
}

const InMailKeys = {
    inMail: ['in-mail']
}

const eventKeys = {
    events: ['events']
}

export {
    profileKeys,
    InMailKeys,
    homeQueryKeys,
    opinionKeys,
    eventKeys
}