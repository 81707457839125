import Header from 'components/Header/Header'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import React, { useEffect, useState } from 'react'
import styles from './ScheduleOpinions.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { api } from 'api/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap';
import Opinion from 'components/Opinion/Opinion'
import { getPageNumberFromUrl } from 'hooks/Utils/pageParams'

const ScheduleOpinions = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const [opinions, setOpinions] = useState([])
    const [page, setPage] = useState(1)

    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [networkError, setNetworkError] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [isRefresh, setIsRefresh] = useState(true)

    const goBack = () => navigate(-1)

    useEffect(() => {
        fetchOpinions()
    }, [])


    const fetchOpinions = async () => {
        try {
            if (!isLoading && hasMore) {
                setIsLoading(true)
                const request = await api(`api/opinions/queued?page=${page}`, 'GET', {}, accessToken)
                const response = await request.json()
                console.log('Response fetch opinions home:', response)
                if (request.status === 200) {
                    if (response.success) {

                        // PAGINATE
                        const nextPage = getPageNumberFromUrl(response?.data?.next_page_url)
                        const prevPage = getPageNumberFromUrl(response?.data?.prev_page_url)
                        if (nextPage) {
                            setPage(prevPage)
                            setHasMore(true)
                        } else {
                            setHasMore(false)
                        }

                        if (response?.data?.data?.length === 0) {
                            setIsEmpty(true)
                        } else {
                            setIsEmpty(false)
                        }

                        setOpinions(response?.data?.data)

                    }
                }
                setIsRefresh(false)
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            console.warn(error.message);
        }
    };

    const refetchOpinions = async () => {
        try {
            if (!isLoading) {
                setIsLoading(true)
                const request = await api(`api/opinions/queued?page=1`, 'GET', {}, accessToken)
                const response = await request.json()
                console.log('Response fetch opinions home:', response)
                if (request.status === 200) {
                    if (response.success) {

                        // PAGINATE
                        const nextPage = getPageNumberFromUrl(response?.data?.next_page_url)
                        const prevPage = getPageNumberFromUrl(response?.data?.prev_page_url)
                        if (nextPage) {
                            setPage(prevPage)
                            setHasMore(true)
                        } else {
                            setHasMore(false)
                        }

                        if (response?.data?.data?.length === 0) {
                            setIsEmpty(true)
                        } else {
                            setIsEmpty(false)
                        }

                        setOpinions(response?.data?.data)

                    }
                }
                setIsRefresh(false)
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            console.warn(error.message);
        }
    };


    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading_opinion')}
                    </h6>
                </div>
            </div>
        )
    }


    const deleteOpinion = (opinion) => {
        try {
            const filtedOpinions = opinions?.filter(item => item?.sond_id !== opinion?.sond_id)
            setOpinions(filtedOpinions)
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={styles.container}>
            <Header
                title={i18n.language === 'en'
                    ? 'Favorites'
                    : 'Favoris'
                }
                goBack={goBack}
            />
            <div className={styles.content}>
                <div id="infiniteScrollDiv">
                    <InfiniteScroll
                        dataLength={opinions?.length}
                        next={fetchOpinions}
                        hasMore={hasMore}
                        loader={renderLoader()}
                        scrollableTarget="infiniteScrollDiv"
                        scrollThreshold={0.9}
                        style={{
                            width: '99.5%',
                            paddingBottom: '100px'
                        }}>
                        {opinions?.map((item, index) => {
                            return (
                                <Opinion
                                    key={index}
                                    item={item}
                                    index={index}
                                    opinion={item}
                                    deleteOpinion={deleteOpinion}
                                    sourceFrom={'Bookmark'}
                                />
                            )
                        })}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}

export default ScheduleOpinions
