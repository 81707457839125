import React, { memo } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MdOutlineErrorOutline } from 'react-icons/md'

const EmptyPage = ({
    text = 'Indisponible'
}) => {

    const { t } = useTranslation()


    return (
        <div style={{ backgroundColor: 'white', height: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

            <div style={{ textAlign: 'center' }}>

                <MdOutlineErrorOutline size={55} color='#808080' />

                <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '18px' }}>
                    {text}
                </h6>

            </div>

        </div>
    )
}

export default memo(EmptyPage)