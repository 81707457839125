import ProgressBar from '@ramonak/react-progress-bar'
import React, { memo, useEffect, useState } from 'react'
import { api } from '../../../../api/api'
import { useAccessToken, useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import { color } from '../../../../hooks/Utils/color'
import SondPicture from '../SondPicture/SondPicture'
import SondVideo from '../SondVideo/SondVideo'

const SondOption = ({
    sond_id,
    sond_caption,
    sond_user,
    options,

    opt_id,
    opt_image,
    opt_type,

    opt_size,
    opt_extension,
    opt_duration,
    opt_width,
    opt_height,
    opt_mime_type,

    opt_thumbnail,
    opt_libelle,
    votes_count,

    total_count,
    can_user_vote,
    get_user_vote,

    updateOpinion,
}) => {

    const accessToken = useAccessToken()
    const { data: authData } = useAuthReducer()
    const [votes, setVotes] = useState(0)
    const [isChecked, setIsChecked] = useState(false)
    const [error, setError] = useState(false)



    useEffect(() => {
        if (total_count != 0) {
            setVotes((votes_count * 100 / total_count).toFixed(0))
        }
    }, [votes_count, total_count])

    useEffect(() => {
        if (get_user_vote?.length !== 0 && get_user_vote[0]?.vote_option === opt_id) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }, [get_user_vote])


    const onChange = async () => {

        try {
            const body = {
                vote_option: opt_id,
                vote_opinion: sond_id
            }
            const request = await api('api/votes', 'POST', body, accessToken)
            const response = await request.json()
            console.log('response of vote:', response?.opinion)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    updateOpinion(response?.opinion)
                    if (response?.opinion?.sond_user !== authData?.user?.user_id && !response?.already_voted) {
                        // sendNotification(sond_user, {
                        //     title: `${authContextState?.user?.user_surname} ${authContextState?.user?.user_name} a voté:`,
                        //     body: 'Voir le vote',
                        //     url: `https://myopinion.ci/opinion/select/0/${sond_id}`,
                        // })
                    }
                }
            }
        } catch ({ message }) {
            setError('l')
            throw new Error(message)
        }

    }



    return (
        <div style={{ width: '50%' }}>
            {/* VIDEO OPTION */}
            {!!opt_image && opt_type === 'video' && (
                <div style={{ border: '1px white solid' }}>
                    <SondVideo
                        uri={opt_image}
                        thumbnail={opt_thumbnail}
                    />
                </div>
            )}

            {/* IMAGE OPTION */}
            {!!opt_image && opt_type === 'image' &&
                <div style={{ width: '100%', height: '350px', backgroundColor: '#f1f1f1' }}>
                    <img
                        src={opt_image}
                        alt=''
                        style={{
                            width: `100%`,
                            height: '100%',
                            objectFit: 'cover',
                            border: '1px white solid'
                        }} />
                </div>
            }

            <div onClick={onChange} style={{ cursor: 'pointer', padding: '10px', border: '1px #f1f1f1 solid' }}>

                <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <input
                        type='radio'
                        name={opt_libelle}
                        value={opt_id}
                        checked={isChecked}
                        onChange={onChange}
                        style={{ cursor: 'pointer' }}
                    />
                    <b style={{ color: color.primary, marginLeft: '10px' }}>
                        {opt_libelle}
                    </b>
                    <b style={{marginLeft: 'auto', color: '#808080'}}>{votes_count}/{total_count}</b>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width:'80%'}}>
                    <ProgressBar
                        bgColor={color.primary}
                        completed={votes}
                        maxCompleted={100}
                        isLabelVisible={false}
                        labelSize='13px'
                        height={15}
                    />
                    </div>
                    <b>{votes}%</b>
                </div>
            </div>

        </div>
    )
}

export default memo(SondOption)