import React from 'react'
import styles from './InMailAttachCard.module.css'
import { image_extensions } from 'constants/formFiles'
import { audio_extensions } from 'constants/formFiles'
import { video_extensions } from 'constants/formFiles'
import { word_extensions } from 'constants/formFiles'
import { excel_extensions } from 'constants/formFiles'
import { powerpoint_extensions } from 'constants/formFiles'
import { access_extensions } from 'constants/formFiles'
import { useTranslation } from 'react-i18next'



const InMailAttachCard = ({
    attachments
}) => {

    const { t, i18n } = useTranslation()

    const openFile = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }


    return (
        <div className={styles.container}>
            {attachments?.map((attach, index) => {
                return (
                    <div key={index?.toString()} onClick={() => openFile(attach?.attach_url)} className={styles.contentContainer}>
                        <div className={styles.imageBox}>
                            <img
                                src={
                                    image_extensions?.includes(attach?.attach_extension)
                                        ? require('assets/docs/image_icon.png')
                                        : audio_extensions?.includes(attach?.attach_extension)
                                            ? require('assets/docs/audio.png')
                                            : video_extensions?.includes(attach?.attach_extension)
                                                ? require('assets/docs/video_icon.png')
                                                : word_extensions?.includes(attach?.attach_extension)
                                                    ? require('assets/docs/word_icon.png')
                                                    : attach?.attach_extension === 'pdf'
                                                        ? require('assets/docs/pdf_icon.png')
                                                        : excel_extensions?.includes(attach?.attach_extension)
                                                            ? require('assets/docs/excell_icon.png')
                                                            : powerpoint_extensions?.includes(attach?.attach_extension)
                                                                ? require('assets/docs/pptx_icon.png')
                                                                : access_extensions?.includes(attach?.attach_extension) === 'accdb'
                                                                    ? require('assets/docs/access_icon.png')
                                                                    : attach?.attach_extension === 'txt'
                                                                        ? require('assets/docs/txt.png')
                                                                        : require('assets/docs/file_icon.png')
                                }
                                resizeMode='contain'
                                className={styles.image}
                            />
                        </div>
                        <p className={styles.name}>
                            {attach?.attach_filename}
                        </p>
                    </div>
                )
            })}
        </div>
    )
}

export default InMailAttachCard